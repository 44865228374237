import yaml from 'js-yaml'
import { YamlConfig } from './YamlConfig'
interface Settings {
  domain: string
  audience: string
  organizationId: string
  clientId: string
  accountsApiUrl: string
  workspaceApiUrl: string
  apiBaseUrl: string
  accountsUrl: string
  mapsApiUrl: string
  mapsPublicUrl: string
  recurlyUrl: string
  publicUrl: string
  hubspotId: string
  statsApiUrl: string
  hubspotLimitFormId: string
  hubspotSubscriptionFormId: string
  allScopes: string
  doCatalogApiUrl: string
  googleMapsApiKey: string
  importApiUrl: string
  publicCatalogUrl: string
  bigqueryOAuthEnabled: boolean
  enableFeaturedApplications: boolean
  accessInSchema: string
  localStorageOrgIdKey: string
  studentClientId: string
  loginAsStudentKey: string
  launchDarklyClientSideId: string
  cartoVersion: string
  documentationUrl: string
  feedbackUrl: string
  supportEmail: string
}
const settings: Settings = {
  domain: '',
  audience: '',
  organizationId: '',
  clientId: '',
  accountsApiUrl: '',
  workspaceApiUrl: '',
  apiBaseUrl: '',
  accountsUrl: '',
  statsApiUrl: '',
  publicUrl: process.env.PUBLIC_URL,
  publicCatalogUrl: '',
  mapsApiUrl: '',
  mapsPublicUrl: '',
  recurlyUrl: '',
  hubspotId: '',
  hubspotLimitFormId: '',
  hubspotSubscriptionFormId: '',
  doCatalogApiUrl: '',
  googleMapsApiKey: '',
  allScopes:
    'read:current_user update:current_user read:connections write:connections read:maps write:maps read:account admin:account',
  importApiUrl: '',
  bigqueryOAuthEnabled: false,
  enableFeaturedApplications: false,
  accessInSchema: 'carto',
  localStorageOrgIdKey: 'orgId',
  studentClientId: '',
  loginAsStudentKey: 'student_account',
  launchDarklyClientSideId: '',
  cartoVersion: '',
  documentationUrl: 'https://docs.carto.com/',
  feedbackUrl: 'https://cartohq.typeform.com/to/a4YHHwPS',
  supportEmail: 'support@carto.com'
}

export const SE_PROJECT_BY_REGION = {
  // AUSTRALIA
  'australia-southeast1': 'carto-un-au-se1',
  'australia-southeast2': 'carto-un-au-se2',
  // EUROPE
  EU: 'carto-un-eu',
  'europe-west1': 'carto-un-eu-we1',
  'europe-west2': 'carto-un-eu-we2',
  'europe-west3': 'carto-un-eu-we3',
  'europe-west4': 'carto-un-eu-we4',
  'europe-west6': 'carto-un-eu-we6',
  'europe-north1': 'carto-un-eu-no1',
  'europe-central2': 'carto-un-eu-ce2',
  // ASIA
  'asia-northeast1': 'carto-un-as-ne1',
  'asia-northeast2': 'carto-un-as-ne2',
  'asia-northeast3': 'carto-un-as-ne3',
  'asia-southeast1': 'carto-un-as-se1',
  'asia-southeast2': 'carto-un-as-se2',
  'asia-south1': 'carto-un-as-so1',
  'asia-south2': 'carto-un-as-so2',
  'asia-east1': 'carto-un-as-ea1',
  'asia-east2': 'carto-un-as-ea2',
  // NORTH AMERICA
  'northamerica-northeast1': 'carto-un-na-ne1',
  'northamerica-northeast2': 'carto-un-na-ne2',
  // SOUTH AMERICA
  'southamerica-east1': 'carto-un-sa-ea1',
  'southamerica-west1': 'carto-un-sa-we1',
  // US
  'us-central1': 'carto-un-us-ce1',
  'us-west1': 'carto-un-us-we1',
  'us-west2': 'carto-un-us-we2',
  'us-west3': 'carto-un-us-we3',
  'us-west4': 'carto-un-us-we4',
  'us-east1': 'carto-un-us-ea1',
  'us-east4': 'carto-un-us-ea4'
}

export const DEFAULT_SE_PROJECT = 'carto-un'

export async function loadConfig() {
  const configUrl = process.env.REACT_APP_CONFIG_URL || '/config.yaml'

  const config = await fetchConfigYaml(configUrl)

  const { auth0, apis, hubspot, launchDarkly, cartoVersion } = config

  // AUTH0
  settings.domain = auth0.domain
  settings.audience = auth0.audience
  settings.clientId = auth0.clientId
  settings.studentClientId = auth0.studentClientId
  settings.organizationId = auth0.organizationId

  // APIs
  settings.apiBaseUrl = apis.baseUrl
  settings.accountsApiUrl = apis.accountsUrl
  settings.workspaceApiUrl = apis.workspaceUrl
  settings.mapsApiUrl = `${apis.baseUrl}/v3/maps`
  settings.statsApiUrl = `${apis.baseUrl}/v3/stats`
  settings.doCatalogApiUrl = apis.doUrl
  settings.importApiUrl = `${apis.importUrl || apis.baseUrl}/v3/imports`
  settings.recurlyUrl = `${apis.recurlyUrl}`

  // Hubspot
  settings.hubspotId = hubspot.id
  settings.hubspotLimitFormId = hubspot.limitFormId
  settings.hubspotSubscriptionFormId = hubspot.requestSubscriptionFormId

  // LaunchDarkly
  settings.launchDarklyClientSideId = launchDarkly.clientSideId

  // CARTO Version
  const selfHosted = cartoVersion.selfHosted
  settings.cartoVersion = selfHosted ? `SH_${selfHosted}` : 'SaaS'

  // common
  const accountsUrl = config.accountsUrl.endsWith('/')
    ? config.accountsUrl.substring(0, config.accountsUrl.length - 1)
    : config.accountsUrl // ensure we don't have a trailing slash
  settings.accountsUrl = accountsUrl
  settings.mapsPublicUrl = config.publicMapUrl
  settings.googleMapsApiKey = config.googleMapsApiKey
  settings.publicCatalogUrl = config.publicCatalogUrl
  settings.accessInSchema = config.accessInSchema

  // TODO: this flag should be managed in Backend (maybe in /me),
  // they already have a flag that we could use to decide if we show or hide OAuth option
  settings.bigqueryOAuthEnabled = config.bigqueryOAuthEnabled
  settings.enableFeaturedApplications = config.enableFeaturedApplications
}

export async function fetchConfigYaml(configUrl: string) {
  let yamlText
  try {
    const response = await fetch(configUrl)

    if (!response.ok) {
      throw new Error(`File status code ${response.status}.`)
    }
    yamlText = await response.text()
    if (!yamlText) {
      throw new Error(`Empty config file detected.`)
    }
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Cannot download file ${configUrl}. ${msg}`)
  }

  let config: YamlConfig
  try {
    config = yaml.load(yamlText) as YamlConfig
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Error parsing config file. ${msg}`)
  }

  return config
}

export default settings
